<template>
  <div class="container-fluid h-100 p-0">
    <div class="row h-100">
      <div class="card card-panel border-0 h-100 pt-0" style="width: 100%">
        <div class="card-body d-flex flex-column pt-0 pb-0 w-100">
          <template v-if="UI.exibirIdentificacao">
            <div class="row">
              <div class="col-3">
                <span class="title">Conferência</span>
              </div>
              <div class="col-6">
                <span class="cabecalho-info">
                  (Natureza:
                  <span class="font-italic link-pesquisa" @click="alterarIdentificacao">{{
                    selecionado.natureza.Descricao
                  }}</span>
                  / Depósito:
                  <span class="font-italic link-pesquisa" @click="alterarIdentificacao"> {{ selecionado.deposito.Nome }}</span>
                  )
                </span>
                <span class="cursor-pointer" @click="alterarIdentificacao">
                  <i class="fa fa-edit text-primary"></i>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="naturezaSelect">Natureza operação</label>
                  <select v-model="selecionado.natureza" class="form-control form-control-sm" id="naturezaSelect">
                    <option v-for="natureza in naturezas" :value="natureza" :key="natureza.Codigo">
                      {{ natureza.Descricao }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="naturezaSelect">Depósito</label>
                  <select v-model="selecionado.deposito" class="form-control form-control-sm" id="naturezaSelect">
                    <option v-for="deposito in depositos" :value="deposito" :key="deposito.Id">
                      {{ deposito.Nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <hr class="w-100" />
          </template>
          <div class="row">
            <div class="col h-100">
              <div class="row">
                <div class="col-3">
                  <span class="title">Notas</span>
                </div>
                <div class="col-6">
                  <template v-if="!UI.exibirIdentificacao">
                    <span class="cabecalho-info">
                      (Natureza:
                      <span class="font-italic link-pesquisa" @click="alterarIdentificacao">{{
                        selecionado.natureza.Descricao
                      }}</span>
                      / Depósito:
                      <span class="font-italic link-pesquisa" @click="alterarIdentificacao">
                        {{ selecionado.deposito.Nome }}</span
                      >
                      )
                    </span>
                    <span class="cursor-pointer" @click="alterarIdentificacao">
                      <i class="fa fa-edit text-primary"></i>
                    </span>
                  </template>
                </div>
              </div>
              <div class="row" v-if="true">
                <div class="col">
                  <button
                    id="button-editar"
                    type="button"
                    class="btn btn-default btn-sm mt-1 mb-1"
                    v-tooltip.bottom="tooltipEdicaoConferencia"
                    @click="abrirConferenciaEdicao"
                  >
                    <i class="fas fa-edit"></i> Editar
                  </button>
                </div>

                <div class="col-3 text-right">
                  <button
                    id="button-filtrar"
                    v-show="!UI.exibirFiltro"
                    type="button"
                    class="btn btn-default btn-sm mt-1 mb-1"
                    @click.prevent="UI.exibirFiltro = true"
                  >
                    <i class="fas fa-search"></i> Filtrar
                  </button>
                  <div class="input-group input-group-sm pb-1" v-show="UI.exibirFiltro">
                    <input
                      id="input-pesquisar"
                      type="text"
                      v-model="UI.filtro"
                      class="form-control form-control border-right-0"
                    />

                    <div class="input-group-append">
                      <button type="button" class="btn btn-outline-default border border-left-0" @click.prevent="esconderFiltro">
                        <span>&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
              class="table-responsive mb-3"
              style="height: calc(100vh - 200px); overflow-y: auto; overflow-x: hidden !important"
              >
              <div v-for="(nota, idx) in UI.itensAgrupadosPorNota" :key="idx">
                <div class="row my-1">
                    <!-- <pre>{{nota.Itens}}</pre> -->
                    <div class="col-12">
                      <toogle-panel class="shadow-sm">
                        <div slot="title" class="d-flex">
                          <strong>
                            <span id="span-nota-serie" class="text-uppercase">
                              <a @click="abrirDocumentoInterno(nota.Id)" href="#"
                                >Doc. Ref.: {{ nota.Numero }}/{{ nota.Itens[0].Serie }}</a
                              >
                            </span>
                            <span id="span-nota-chave" class="small pl-1"> (Chave:{{ nota.Itens[0].Chave }}) </span>
                            <!-- <span id="span-nota-qtde-itens" class="small pl-1">(Quantidade Itens: {{ nota.Itens.length }})</span> -->
                          </strong>
                        </div>
                        <div class="col" slot="action-button">
                          <div class="col-3">

                            <button  v-if="dadosAdicionaisPreenchidos(nota)"
                              id="button-dados-adicionais"
                              type="button"
                              class="btn btn-success btn-sm"
                              @click="abrirDadosAdicionais(nota)"
                              >Dados Adicionais</button
                            >
                            <button v-else
                              id="button-dados-adicionais"
                              type="button"
                              class="btn btn-secondary btn-sm"
                              @click="abrirDadosAdicionais(nota)"
                              >Dados Adicionais</button
                            >
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div
                              class="
                                table-responsive
                                d-flex
                                flex-column
                                first-column-fixed
                                second-column-fixed
                                h-100
                                overflow-y-hidden
                              "
                            >
                              <table class="table table-sm table-hover table-striped table-bordered overflow-auto">
                                <thead>
                                  <tr>
                                    <th class="border-right-0"></th>
                                    <th class="border-right-0"></th>
                                    <th colspan="11" class="border-left-0"></th>
                                    <th class="text-center text-nowrap" colspan="4">ICMS</th>
                                    <th class="text-center text-nowrap" colspan="3">FCP</th>
                                    <template v-if="!preferenciaEmpresa.MostraIcmsStObs">
                                      <th class="text-center text-nowrap" colspan="3">ICMS-ST</th>
                                      <th class="text-center text-nowrap" colspan="3">FCP-ST</th>
                                    </template>
                                    <th class="text-center text-nowrap" :colspan="ipiDevolvidoCampoProprio ? 2 : 4">
                                      IPI
                                      <template v-if="ipiDevolvidoCampoProprio">
                                        <span
                                          v-tooltip.right="ipiCampoProprioMensagem"
                                          class="btn btn-outline-primary btn-xs rounded cursor-default"
                                        >
                                          <i class="fas fa-exclamation" />
                                        </span>
                                      </template>
                                    </th>
                                    <th class="text-center text-nowrap" colspan="4">PIS</th>
                                    <th class="text-center text-nowrap" colspan="4">COFINS</th>
                                  </tr>
                                  <tr>
                                    <th class="text-left text-nowrap"></th>
                                    <th class="text-left text-nowrap">Produto</th>
                                    <th class="text-right text-nowrap">Doc. Ref.</th>
                                    <th class="text-center text-nowrap">CFOP</th>
                                    <th class="text-right text-nowrap">NCM</th>
                                    <th class="text-right text-nowrap">CEST</th>
                                    <th class="text-right text-nowrap">Quantidade</th>
                                    <th class="text-right text-nowrap">Preço Unitário (R$)</th>
                                    <th class="text-right text-nowrap">Preço Total (R$)</th>
                                    <th class="text-right text-nowrap">Desconto (R$)</th>
                                    <th class="text-right text-nowrap">Frete (R$)</th>
                                    <th class="text-right text-nowrap">Seguro (R$)</th>
                                    <th class="text-right text-nowrap">Despesas (R$)</th>

                                    <th class="text-center text-nowrap">
                                      <div v-if="simplesNacional">CSOSN</div>
                                      <div v-else>CST</div>
                                    </th>
                                    <th class="text-right text-nowrap">Base (R$)</th>
                                    <th class="text-right text-nowrap">Alíq. (%)</th>
                                    <th class="text-right text-nowrap">Valor (R$)</th>

                                    <th class="text-right text-nowrap">Base (R$)</th>
                                    <th class="text-right text-nowrap">Alíq. (%)</th>
                                    <th class="text-right text-nowrap">Valor (R$)</th>

                                    <template v-if="!preferenciaEmpresa.MostraIcmsStObs">
                                      <th class="text-right text-nowrap">Base (R$)</th>
                                      <th class="text-right text-nowrap">Alíq. (%)</th>
                                      <th class="text-right text-nowrap">Valor (R$)</th>

                                      <th class="text-right text-nowrap">Base (R$)</th>
                                      <th class="text-right text-nowrap">Alíq. (%)</th>
                                      <th class="text-right text-nowrap">Valor (R$)</th>
                                    </template>

                                    <th class="text-center text-nowrap">CST</th>
                                    <th class="text-right text-nowrap" v-if="!ipiDevolvidoCampoProprio">Base (R$)</th>
                                    <th class="text-right text-nowrap" v-if="!ipiDevolvidoCampoProprio">Alíq. (%)</th>
                                    <th class="text-right text-nowrap">Valor (R$)</th>

                                    <th class="text-center text-nowrap">CST</th>
                                    <th class="text-right text-nowrap">Base (R$)</th>
                                    <th class="text-right text-nowrap">Alíq. (%)</th>
                                    <th class="text-right text-nowrap">Valor (R$)</th>

                                    <th class="text-center text-nowrap">CST</th>
                                    <th class="text-right text-nowrap">Base (R$)</th>
                                    <th class="text-right text-nowrap">Alíq. (%)</th>
                                    <th class="text-right text-nowrap">Valor (R$)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(item, index) in nota.Itens" :key="index">
                                    <th class="text-left text-nowrap checkbox-column">
                                      <input
                                        type="checkbox"
                                        id="item.CodigoProduto"
                                        :checked="item.Selecionado"
                                        @change="selecionarItem($event.target.checked, item)"
                                      />
                                    </th>
                                    <td class="text-left text-nowrap">
                                      <span :title="item.Descricao">{{ item.Descricao | truncate(50) }} </span>
                                      <span class="small">{{ labelCodigoESerialProduto(item) }}</span>
                                    </td>
                                    <td class="text-right">{{ item.Numero }}/{{ item.Serie }}</td>
                                    <td class="text-center">
                                      {{ item.Cfop }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.Ncm }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.Cest }}
                                    </td>
                                    <td class="text-right">
                                      {{ item.Quantidade }}
                                      <small class="text-muted">({{ item.UnidadeSelecionada }})</small>
                                    </td>
                                    <td class="text-right">
                                      {{ item.PrecoUnitario | monetarioSimples }}
                                    </td>
                                    <td class="text-right">
                                      {{ item.ValorBruto | monetarioSimples }}
                                    </td>
                                    <td class="text-right">
                                      {{ item.ValorDesconto | monetarioSimples }}
                                    </td>
                                    <td class="text-right">
                                      {{ item.ValorFrete | monetarioSimples }}
                                    </td>
                                    <td class="text-right">
                                      {{ item.ValorSeguro | monetarioSimples }}
                                    </td>
                                    <td class="text-right">
                                      {{ item.ValorDespesas | monetarioSimples }}
                                    </td>

                                    <td class="text-center text-nowrap">{{ item.IcmsCstCsosn }}</td>
                                    <td class="text-right">{{ item.IcmsBase | monetarioSimples }}</td>
                                    <td class="text-right">{{ item.IcmsAliquota | decimal }}</td>
                                    <td class="text-right">{{ item.IcmsValor | monetarioSimples }}</td>

                                    <td class="text-right">{{ item.FcpBase | monetarioSimples }}</td>
                                    <td class="text-right">{{ item.FcpAliquota | decimal }}</td>
                                    <td class="text-right">{{ item.FcpValor | monetarioSimples }}</td>

                                    <template v-if="!preferenciaEmpresa.MostraIcmsStObs">
                                      <td class="text-right">{{ item.IcmsStBase | monetarioSimples }}</td>
                                      <td class="text-right">{{ item.IcmsStAliquota | decimal }}</td>
                                      <td class="text-right">{{ item.IcmsStValor | monetarioSimples }}</td>

                                      <td class="text-right">{{ item.FcpStBase | monetarioSimples }}</td>
                                      <td class="text-right">{{ item.FcpStAliquota | decimal }}</td>
                                      <td class="text-right">{{ item.FcpStValor | monetarioSimples }}</td>
                                    </template>

                                    <td class="text-center">{{ item.IpiCst }}</td>
                                    <td class="text-right" v-if="!ipiDevolvidoCampoProprio">{{
                                      item.IpiBase | monetarioSimples
                                    }}</td>
                                    <td class="text-right" v-if="!ipiDevolvidoCampoProprio">{{ item.IpiAliquota | decimal }}</td>
                                    <td class="text-right">{{ item.IpiValor | monetarioSimples }}</td>

                                    <td class="text-center">{{ item.PisCofinsCst }}</td>
                                    <td class="text-right">{{ item.PisBase | monetarioSimples }}</td>
                                    <td class="text-right">{{ item.PisAliquota | decimal }}</td>
                                    <td class="text-right">{{ item.PisValor | monetarioSimples }}</td>

                                    <td class="text-center">{{ item.PisCofinsCst }}</td>
                                    <td class="text-right">{{ item.CofinsBase | monetarioSimples }}</td>
                                    <td class="text-right">{{ item.CofinsAliquota | decimal }}</td>
                                    <td class="text-right">{{ item.CofinsValor | monetarioSimples }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="col" v-if="nota.Totalizadores">
                            <hr class="w-100" v-show="UI.exibirTotalizadores" />
                            <div class="row mt-auto">
                              <div class="col">
                                <div v-show="UI.exibirTotalizadores">
                                  <table id="table-totalizadores-impostos" class="table table-sm m-0 tabela-totalizadores">
                                    <thead>
                                      <tr>
                                        <th>Valor ICMS</th>
                                        <th>Valor FCP</th>
                                        <template v-if="!preferenciaEmpresa.MostraIcmsStObs">
                                          <th>Valor ICMS-ST</th>
                                          <th>Valor FCP-ST</th>
                                        </template>
                                        <th>
                                          <span>
                                            {{ labelIpiTotalizador }}
                                          </span>
                                          <template v-if="ipiDevolvidoCampoProprio">
                                            <span
                                              v-tooltip.top="ipiCampoProprioMensagem"
                                              class="btn btn-outline-primary btn-xs rounded cursor-default"
                                            >
                                              <i class="fas fa-exclamation" />
                                            </span>
                                          </template>
                                        </th>
                                        <th>Valor PIS/COFINS</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{{ nota.Totalizadores.icmsValor | monetario }}</td>
                                        <td>{{ nota.Totalizadores.fcpValor | monetario }}</td>
                                        <template v-if="!preferenciaEmpresa.MostraIcmsStObs">
                                          <td>{{ nota.Totalizadores.icmsStValor | monetario }}</td>
                                          <td>{{ nota.Totalizadores.fcpStValor | monetario }}</td>
                                        </template>
                                        <td>{{ nota.Totalizadores.ipiValor | monetario }}</td>
                                        <td>{{ nota.Totalizadores.pisCofinsValor | monetario }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    id="table-totalizadores-acrescimos-descontos"
                                    class="table table-sm m-0 tabela-totalizadores"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Valor Produtos</th>
                                        <th>Frete</th>
                                        <th>Seguro</th>
                                        <th>Desconto</th>
                                        <th
                                          :class="[preferenciaEmpresa.MostraIcmsStObs && nota.Totalizadores.stValor > 0 ? 'w-40' : '']"
                                          >Outras Desp. Acessórias</th
                                        >
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{{ nota.Totalizadores.totalProdutosValor | monetario }}</td>
                                        <td>{{ nota.Totalizadores.frete | monetario }}</td>
                                        <td>{{ nota.Totalizadores.seguro | monetario }}</td>
                                        <td>{{ nota.Totalizadores.desconto | monetario }}</td>
                                        <td>
                                          <span
                                            >{{ nota.Totalizadores.despesas | monetario }}<span class="small"> (despesas)</span></span
                                          >
                                          <template v-if="preferenciaEmpresa.MostraIcmsStObs && nota.Totalizadores.stValor > 0">
                                            <span class="pr-1">
                                              + {{ nota.Totalizadores.stValor | monetario
                                              }}<span class="small"> (ICMS-ST/FCP-ST)</span></span
                                            >
                                          </template>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="row">
                                  <div class="col text-right" v-show="UI.exibirTotalizadores">
                                    <span class="text-primary"
                                      ><strong>Valor total da nota:</strong> {{ nota.Totalizadores.notaValor | monetario }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </toogle-panel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer pb-0 bg-white">
          <div class="row justify-content-between">
            <div class="col-3">
              <button id="button-voltar" type="button" class="btn btn-default btn-sm btn-block" @click="voltar"
                ><i class="fas fa-arrow-left"></i> Voltar</button
              >
            </div>
            <div class="col-3">
              <button id="button-devolver-finalizar-notas" type="button" class="btn btn-primary btn-sm btn-block" @click="finalizar"
                >Devolver</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <DevolucaoConferenciaEdicao
      v-if="UI.exibirConferenciaEdicao"
      :produtosEdicao="produtosEdicao"
      :quantidadeItensSelecionados="quantidadeItensSelecionados"
      @fechar="fecharConferenciaEdicao"
    />
    <DevolucaoConferenciaDadosAdicionaisPorNota
      v-if="UI.exibirDadosAdicionais"
      @fechar="fecharDadosAdicionais"
      :nota="notaDadosAdicionais"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import swal from '@/common/alerts';
  import numeral from 'numeral';
  import loading from '@/common/loading';
  import Decimal from 'decimal.js';
  import VTooltip from 'v-tooltip';
  import Vue from 'vue';
  import 'numeral/locales/pt-br';
  import arrayApi from '@/core/apis/arrayApi';
  import DevolucaoConferenciaEdicao from './DevolucaoConferenciaEdicao';
  import DevolucaoConferenciaDadosAdicionaisPorNota from './DevolucaoConferenciaDadosAdicionaisPorNotas';
  import tooglePanel from '@/components/TooglePanel.vue';

  numeral.locale('pt-br');
  Decimal.set({ rounding: 6 });
  Vue.use(VTooltip);

  export default {
    name: 'DevolucaoConferencia',
    components: {
      DevolucaoConferenciaEdicao,
      DevolucaoConferenciaDadosAdicionaisPorNota,
      tooglePanel,
    },
    data(vm) {
      return {
        UI: {
          exibirTotalizadores: true,
          exibirIdentificacao: false,
          exibirFiltro: false,
          itens: [],
          itensAgrupadosPorNota: [],
          itensAgrupadosPorNota2: vm.$store.state.devolucao.Conferencia.Referenciados,
          filtro: null,
          exibirConferenciaEdicao: false,
          exibirDadosAdicionais: false,
          notaDadosAdicionais: null,
        },
        selecionado: {
          natureza: {},
          deposito: {},
        },
        produtosEdicao: [],
        listaNotasOriginal:[],
      };
    },

    watch: {
      'UI.filtro': {
        handler() {
          this.aplicarFiltro();
        },
        immediate: true,
      },
      itens(newValue) {
        this.UI.itens = newValue;
        let _notasAgrupadas = arrayApi.groupByObject(this.UI.itens, 'Numero', 'Itens').sort(arrayApi.dynamicSort('Numero'));
        this.UI.itensAgrupadosPorNota = this.retornaNotaComTotalizadores(_notasAgrupadas);
        this.listaNotasOriginal = this.UI.itensAgrupadosPorNota;
      },
    },

    filters: {
      monetario(value) {
        return numeral(value).format('$0,0.00');
      },
      monetarioSimples(value) {
        return numeral(value).format('0,0.00'); 
      },
    },
    computed: {
      ...mapGetters({
        dadosAdicionaisPorNota: 'devolucao/obterDadosAdicionaisPorNota',
      }),
      ...mapState({
        produtos: (state) => state.devolucao.Produtos,
        csosns: (state) => state.devolucao.Configuracoes.listasGerais.Csosns,
        notasConferencia: (state) => state.devolucao.Conferencia.Referenciados,
        naturezas: (state) => state.devolucao.Configuracoes.listasGerais.NaturezasOperacao,
        depositos: (state) => state.devolucao.Configuracoes.listasGerais.Depositos,
        preferenciaEmpresa: (state) => state.devolucao.Configuracoes.preferenciasEmpresa,
        dadosAdicionais: (state) => state.devolucao.Conferencia.DadosAdicionais,
      }),
      
      simplesNacional() {
        return this.csosns && this.csosns.length > 0;
      },

      itens() {
        return this.notasConferencia.flatMap((nota) => {
          let _nota = nota; //{ ...nota, Totalizadores: this.calculaTotalizadores(nota) };
          let itensNota = nota.Itens.map((item) => {
            var produto = this.produtos[item.CodigoProduto];

            produto.Selecionado = false;

            if (!item.Cest) item.Cest = produto.Cest;

            return {
              ...produto,
              ...item,
              ..._nota,
            };
          });
          return itensNota;
        });
      },

      quantidadeItensSelecionados() {
        return this.produtosEdicao.length;
      },

      tooltipEdicaoConferencia() {
        const mensagem = this.edicaoConferenciaDesabilitado
          ? 'Selecionar um ou mais produtos para edição'
          : 'Editar produtos selecionados';

        return { content: mensagem, ...this.bootstrapConflict };
      },

      ipiDevolvidoCampoProprio() {
        return this.notasConferencia.some((n) => n.IpiDevolvido);
      },

      ipiCampoProprioMensagem() {
        const mensagem = `Sua empresa não é contribuinte de IPI (Indústria ou Equiparada), <br>por isso enviaremos o valor do IPI na tag &lt;vIPIDevolv&gt; do XML
        <br>para que seu fornecedor possa receber o crédito deste imposto.
        <br><br>
        Não se preocupe, mesmo que o valor não apareça no campo de <br> IPI da DANFE, ele estará em Informações Complementares.`;

        return { content: mensagem, ...this.bootstrapConflict };
      },

      bootstrapConflict() {
        return {
          template:
            '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
          arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
          innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
        };
      },

      labelIpiTotalizador() {
        return this.ipiDevolvidoCampoProprio ? 'Valor IPI Devolvido' : 'Valor IPI';
      },
    },

    mounted() {
      this.iniciarConferencia();
      this.iniciarIdentificacao();
    },

    methods: {
      retornaNotaComTotalizadores(notas) {
        return notas.flatMap((nota) => {
          let dadosNota = nota.Itens[0];
          let _nota = {
            ...nota,
            Serie: dadosNota.Serie,
            Chave: dadosNota.Chave,
            Totalizadores: this.calculaTotalizadores(nota) 
          };
          return _nota;
        });
      },
      calculaTotalizadores(nota) {
        let totalizadores = {
          icmsValor: new Decimal(0),
          fcpValor: new Decimal(0),
          icmsStValor: new Decimal(0),
          fcpStValor: new Decimal(0),
          stValor: new Decimal(0),
          ipiValor: new Decimal(0),
          pisCofinsValor: new Decimal(0),
          totalProdutosValor: new Decimal(0),
          frete: new Decimal(0),
          seguro: new Decimal(0),
          desconto: new Decimal(0),
          despesas: new Decimal(0),
          notaValor: new Decimal(0),
        };

        if (!nota) return totalizadores;

        nota.Itens.forEach((item) => {
          totalizadores.icmsValor = totalizadores.icmsValor.plus(item.IcmsValor).toDP(2);
          totalizadores.fcpValor = totalizadores.fcpValor.plus(item.FcpValor).toDP(2);
          totalizadores.icmsStValor = totalizadores.icmsStValor.plus(item.IcmsStValor).toDP(2);
          totalizadores.fcpStValor = totalizadores.fcpStValor.plus(item.FcpStValor).toDP(2);
          totalizadores.pisCofinsValor = totalizadores.pisCofinsValor.plus(item.PisValor + item.CofinsValor).toDP(2);
          totalizadores.ipiValor = totalizadores.ipiValor.plus(item.IpiValor).toDP(2);
          totalizadores.totalProdutosValor = totalizadores.totalProdutosValor.plus(item.ValorBruto).toDP(2);
          totalizadores.frete = totalizadores.frete.plus(item.ValorFrete).toDP(2);
          totalizadores.seguro = totalizadores.seguro.plus(item.ValorSeguro).toDP(2);
          totalizadores.desconto = totalizadores.desconto.plus(item.ValorDesconto).toDP(2);
          totalizadores.despesas = totalizadores.despesas.plus(item.ValorDespesas).toDP(2);
        });
        totalizadores.stValor = totalizadores.icmsStValor.plus(totalizadores.fcpStValor);

        totalizadores.notaValor = totalizadores.totalProdutosValor;
        totalizadores.notaValor = totalizadores.notaValor.plus(totalizadores.icmsStValor);
        totalizadores.notaValor = totalizadores.notaValor.plus(totalizadores.fcpStValor);
        totalizadores.notaValor = totalizadores.notaValor.plus(totalizadores.ipiValor);
        totalizadores.notaValor = totalizadores.notaValor.plus(totalizadores.frete);
        totalizadores.notaValor = totalizadores.notaValor.plus(totalizadores.despesas);
        totalizadores.notaValor = totalizadores.notaValor.plus(totalizadores.seguro);
        totalizadores.notaValor = totalizadores.notaValor.sub(totalizadores.desconto);

        return totalizadores;
      },
      dadosAdicionaisPreenchidos(nota) {
        //Retorna verdadeiro caso os dados "Tipo de Frete, Transportador, Quantidade, Especie, Peso bruto e Peso liquido" estejam preenchidos
        let dadosAdicionaisNota = this.dadosAdicionaisPorNota.filter(
          (item) => item.NumeroNota == nota.Numero && item.SerieNota == nota.Serie,
        )[0];

        if (!dadosAdicionaisNota) return false;

        let dadosPreenchidos =
          dadosAdicionaisNota.TipoFrete &&
          dadosAdicionaisNota.Transportador &&
          dadosAdicionaisNota.Quantidade &&
          dadosAdicionaisNota.Especie &&
          dadosAdicionaisNota.PesoBruto &&
          dadosAdicionaisNota.PesoLiquido;

        return dadosPreenchidos;
      },
      finalizar() {
        const conferido = {
          Deposito: this.selecionado.deposito,
          NaturezaOperacao: this.selecionado.natureza,
          DadosAdicionais: this.dadosAdicionais,
        };

        if(!conferido.DadosAdicionais.IdCentroCustoDevolucao){
          conferido.DadosAdicionais.IdCentroCustoDevolucao = this.preferenciaEmpresa?.IdCentroCustoDevolucao ?? null
        }

        const notasComDadosAdicionais = this.notasConferencia.map((nota) =>{
          let dadosAdicionaisNota = this.dadosAdicionaisPorNota.filter(
            (item) => item.NumeroNota == nota.Numero && item.SerieNota == nota.Serie,
          )[0];
          return { ...nota
            , DadosAdicionais: dadosAdicionaisNota };
        });

        this.$store.dispatch('devolucao/atualizarNotasConferencia', notasComDadosAdicionais)

        this.$store.dispatch('devolucao/conferido', conferido).finally(() => {
          this.$router.push({ name: 'DevolucaoFinalizacaoPorNotas' });
        });
      },

      iniciarConferencia() {
        loading.exibir();
        this.$store
          .dispatch('devolucao/conferir')
          .catch(() => swal.exibirMensagemErro('Ocorreu um problema'))
          .finally(() => loading.ocultar());
      },

      iniciarIdentificacao() {
        if (this.preferenciaEmpresa.IdDeposito)
          this.selecionado.deposito = this.depositos.find((deposito) => deposito.Id == this.preferenciaEmpresa.IdDeposito);
        if (this.preferenciaEmpresa.CodigoNaturezaOperacao)
          this.selecionado.natureza = this.naturezas.find(
            (natureza) => natureza.Codigo == this.preferenciaEmpresa.CodigoNaturezaOperacao,
          );
        if (!this.selecionado.deposito) this.selecionado.deposito = this.depositos[0];
        if (!this.selecionado.natureza) this.selecionado.natureza = this.naturezas[0];
      },

      aplicarFiltro() {
        if (!this.UI.filtro) {
          this.UI.itensAgrupadosPorNota = this.listaNotasOriginal;
          return;
        }
        var termoPesquisa = this.UI.filtro.toUpperCase();
        this.UI.itensAgrupadosPorNota = this.listaNotasOriginal.filter((item) => {
          return item.Itens.some(
            (i) => i.Descricao.toUpperCase().includes(termoPesquisa) || String(i.CodigoProduto).includes(termoPesquisa))
          }
        );
      },
      alterarIdentificacao() {
        this.UI.exibirIdentificacao = !this.UI.exibirIdentificacao;
      },
      esconderFiltro() {
        this.UI.exibirFiltro = false;
        this.UI.filtro = null;
      },
      voltar() {
        this.$router.push('itens');
      },

      abrirConferenciaEdicao() {
        if (this.quantidadeItensSelecionados <= 0) {
          swal.exibirMensagemAlerta('Selecione um ou mais itens para edição.');
          return;
        }

        this.UI.exibirConferenciaEdicao = true;
      },

      fecharConferenciaEdicao() {
        this.UI.exibirConferenciaEdicao = false;
        this.deselecionarItens();
      },

      selecionarItem(selecionado, item) {
        var indiceProdutoExistente;
        var itemExistente = this.UI.itens.find(function (itemLista, indice) {
          indiceProdutoExistente = indice;
          return itemLista.Transacao == item.Transacao;
        });

        itemExistente.Selecionado = selecionado;

        this.UI.itens.splice(indiceProdutoExistente, 1, itemExistente);

        this.atualizarProdutosEdicao(selecionado, item.Transacao);
      },

      atualizarProdutosEdicao(selecionado, transacao) {
        var indiceProdutoExistente;
        var itemExistente = this.produtosEdicao.find(function (transacaoLista, indice) {
          indiceProdutoExistente = indice;
          return transacaoLista == transacao;
        });

        if (selecionado && !itemExistente) {
          this.produtosEdicao.push(transacao);
        } else {
          this.produtosEdicao.splice(indiceProdutoExistente, 1);
        }
      },

      deselecionarItens() {
        this.UI.itens.forEach((item) => {
          item.Selecionado = false;
        });

        this.produtosEdicao = [];
      },

      abrirDadosAdicionais(nota) {
        this.notaDadosAdicionais = nota;
        this.UI.exibirDadosAdicionais = true;
      },

      fecharDadosAdicionais() {
        this.UI.exibirDadosAdicionais = false;
      },

      labelCodigoESerialProduto(produto) {
        if (produto.Serial) return `(Cod.:${produto.CodigoProduto} | Serial: ${produto.Serial})`;
        else return `(Cod.:${produto.CodigoProduto})`;
      },
    },
  };
</script>

<style scoped>
  .overflow-auto {
    overflow: auto;
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }

  .cursor-default {
    cursor: default !important;
  }

  .btn-xs {
    padding: 0.05rem 0.5rem;
  }
  .btn-dados-adicionais {
    background-color: rgba(163, 163, 163, 0.933);
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .link-pesquisa {
    color: #ff9200 !important;
  }

  .link-pesquisa:hover {
    cursor: pointer !important;
    transition: box-shadow 0.25s ease-out;
    box-shadow: 0 -1px 0 0 #ff9200 inset;
  }

  .cabecalho-info span {
    font-family: 'Dosis', sans-serif;
    font-size: 0.8rem !important;
    text-transform: uppercase;
    font-weight: bolder;
    font-style: italic;
  }

  .first-column-fixed th:first-child,
  .first-column-fixed td:first-child {
    position: sticky;
    left: -1px;
    background-color: white;
  }

  .second-column-fixed th:nth-child(2),
  .second-column-fixed td:nth-child(2) {
    position: sticky;
    left: 22px;
  }

  .second-column-fixed th:nth-child(2) {
    background-color: white;
  }

  .second-column-fixed td:nth-child(2) {
    background-color: #f2f2f2;
  }

  .tabela-totalizadores th {
    width: 15%;
  }

  .checkbox-column {
    width: 25px;
  }

  .height-item {
    height: calc(100vh - 250px);
  }
</style>
